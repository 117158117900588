import { PickerComponent } from './picker/picker.component';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

const PAGE_COMPONENTS = [
  PickerComponent
];

@NgModule({
  declarations: [PAGE_COMPONENTS],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports:[PAGE_COMPONENTS],
})
export class ComponentsModule { }
