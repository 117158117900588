import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { NavController, Platform } from '@ionic/angular';
import { filter, first } from 'rxjs/operators';
import { ReservationService } from './services/reservation-service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent{
  constructor(
    public navCtrl: NavController,
    private router: Router,
    private route: ActivatedRoute,
    private reservationService: ReservationService,
    private swUpdate: SwUpdate,
    private translate: TranslateService,
    private platform: Platform
  ) {

    // this.swUpdate.versionUpdates.subscribe(event => {
    //   this.swUpdate.activateUpdate().then(() => {
    //     document.location.reload();
    //   });
    // });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((x: NavigationEnd) => {

      // if navigation occurs on merchant name
      if (x.id === 1 && this.route.snapshot.params['merchant'])
      {
        this.reservationService.getStoreGuidByMerchantName(this.route.snapshot.params['merchant']).pipe(first()).subscribe(guid => {
          let queryString = this.route.snapshot.queryParams ? `&${ new URLSearchParams(this.route.snapshot.queryParams).toString() }` : '';
          
          if (this.route.snapshot.params['event'])
            {
              queryString += `&sitting=${this.route.snapshot.params['event']}`;
            }

          if (guid)
            this.navCtrl.navigateRoot(`home?id=${guid}${queryString}`);
          else
            this.navCtrl.navigateRoot(['landing']);
        });
      }

      this.route.queryParams.pipe(first()).subscribe(params => {
        if (window.location.pathname !== '/home') 
          return;

        const guid = params['id'];
        if (!guid)
        {
          this.navCtrl.navigateRoot(['landing']);
        }
      });
    });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.googleAnalyticsTrackingHandler();
    });
  }

  private googleAnalyticsTrackingHandler() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-167954691-1', 'auto');
      `;
      document.head.appendChild(script);
    } catch (err) {
      console.error('Error appending google analytics');
      console.error(err);
    }
  }
}
