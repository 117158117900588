import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, first, flatMap, map } from 'rxjs/operators';
import { HttpConfig } from '../core/http-config';
import { MolliePaymentMethods } from '../shared/interfaces/mollie-paymentmethod.interface';

export interface IReservationSettings {
    StoreGuid: string;
    StoreName: string;
    StoreLogoUrl: string;
    ReservationDeposit: number;
    ReservationRequiresDepositPerPerson: boolean;
    ReservationFee: number;
    ReservationFeeCustomerPart: number;
    ReservationRequiresConfirmation: boolean;
    ReservationMaxBookableDuration: number;
    PossibleTimeShifts: string[];
    PreventSameDayReservation: boolean;
    DisabledCalendarWeekDays: number[];
    DisabledCalendarDates: string[];
    Sittings: ISittingsDto[];
    ReservationHouseRules: string;
    ReservationMaxGuests?: number;
    SameDayReservationDeadline?: string;
    ReservationEventDescription?: string;
    ReservationSingleTicketPrice: number;
    ReservationChildTicketPrice: number;
    ReservationEventStartDate:string;
    ReservationEventEndDate: string;
}

export interface ISittingsDto {
    SittingId: number;
    SittingDescription: string;
    ShortCutUrl: string;
    ReservationRules: string;
    SingleTicketPrice: number;
    SingleChildTicketPrice: number;
    StartTime: string;
    EndTime: string;
    ApplicableOnWeekDays: string;
    SittingDisabledCalendarDates: string[];
    LimitSittingDisabledCalendarDatesByTimeSlotId: number[];
    CheckoutAttributes: ICheckoutAttributesDto[];
}

export interface ICheckoutAttributesDto {
    DisplayText: string;
    AttributeDescription: string;
    IsRequired: boolean;
    ControlTypeId: number;
    DisplayOrder: number;
    ValidationMinLength?: number;
    ValidationMaxLength?: number;
    ValidationAllowedFileExtensions: string;
    ValidationFileMaxSize?: number;
    MaxChoices: number;
    MaxQty: number;
    RenderAttribtePerGuest: boolean;

    CheckoutAttributeValue: ICheckoutAttributeValuesDto[];
}

export interface ICheckoutAttributeValuesDto {
    Name: string;
    AttributeValueDescription: string;
    PriceAdjustment: number;
    WeightAdjustment: number;
    IsDefaultSelected: boolean
    DisplayOrder: number;
    ProductVariantImage: string;
    ColorSquaresRgb: string;
}

export interface IReservationRequestDto {
    GuestName: string;
    NumberOfGuests: number;
    NumberOfChildGuests: number;
    Duration: number;
    StartDateTimeAsString: string;
    Email: string;
    MobilePhoneNumber: string;
    Notes: string;
    SittingId: number;
    PaymentMethodId: string;
}

export interface IReservationRequestResult {
    Succeeded: boolean;
    IsConfirmed: boolean;
    PaymentUrl: string;
}

export interface IGetAvailableTimeSlotsRequestDto {
    NumberOfGuests: number;
    StartDateTimeAsString: string;
    SittingId: number;
}

export interface IGetAvailableTimeSlotsResponseDto {
    HasOnlySingleTimeBlock: boolean;
    TimeBlocks: string[];
}

export interface IGetReservableStoresRequestDto {
    SearchTerm: string;
    LatLong: string;
    ReservationRequest: IGetAvailableTimeSlotsRequestDto;
}

export interface IGetReservableStoresResponseDto {
    StoreGuid: string;
    StoreBannerUrls: string[];
    StoreName: string;
    Kitchen: string;
    PublicAddress: string;
    TodayOpenTill: string;
    AvailableTimeSlots: string[];
}

export interface IProcessGuestRegistrationRequestDto {
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
}

@Injectable({ providedIn: 'root' })
export class ReservationService {
    settingsRetrieved$: BehaviorSubject<IReservationSettings> = new BehaviorSubject<IReservationSettings>(null);

    constructor(public httpClient: HttpClient,
        private httpConfig: HttpConfig,
        private storage: Storage) { }

    public getReservationSettings(guid: string): Observable<any> {
        if (!guid)
            return;

        return this.httpClient.get(this.httpConfig.BASE_ENDPOINT + 'reservation/' + guid + '/getreservationsettings')
            .pipe(first(), concatMap((settings: IReservationSettings) => {
                settings.StoreGuid = guid;
                return this.storeSettings(settings).pipe(map(() => {
                    this.settingsRetrieved$.next(settings);
                    return settings;
                }));
            }));
    }

    public processReservationRequest(guid: string, data: IReservationRequestDto): Observable<IReservationRequestResult> {
        return this.httpClient.post(this.httpConfig.BASE_ENDPOINT + 'reservation/' + guid + '/processreservationrequest', data, { observe: 'response' })
            .pipe(flatMap((res: HttpResponse<any>) => {
                if (res.ok) {
                    return of(res.body);
                }
                else {
                    return of(null);
                }
            }));
    }

    public getsettingsRetrievedObserver(): Observable<IReservationSettings> {
        return this.settingsRetrieved$.asObservable();
    }

    public getPaymentStatus(shortreference: string): Observable<string> {
        return this.httpClient.get<string>(this.httpConfig.BASE_ENDPOINT + '/reservation/' + shortreference + '/paymentstatus')
            .pipe(map(result => {
                return result;
            }));
    }

    public getAvailableTimeslots(guid: string, numberOfGuests: number, reservationDate: string, sittingId: number): Observable<IGetAvailableTimeSlotsResponseDto> {
        const data: IGetAvailableTimeSlotsRequestDto = {
            NumberOfGuests: numberOfGuests,
            StartDateTimeAsString: reservationDate,
            SittingId: sittingId
        }

        return this.httpClient.post<IGetAvailableTimeSlotsResponseDto>(this.httpConfig.BASE_ENDPOINT + 'reservation/' + guid + '/getavailabletimeslots', data, { observe: 'response' })
            .pipe(concatMap((res: HttpResponse<any>) => {
                if (res.ok) {
                    return of(res.body as IGetAvailableTimeSlotsResponseDto);
                }
                else {
                    return of(null);
                }
            }));
    }

    public findReservableStores(searchTerm: string, numberOfGuests: number, reservationDate: string, sittingId: number): Observable<any> {
        const data: IGetReservableStoresRequestDto = {
            SearchTerm: searchTerm,
            LatLong: '',
            ReservationRequest: {
                NumberOfGuests: numberOfGuests,
                StartDateTimeAsString: reservationDate,
                SittingId: 0
            }
        }

        return this.httpClient.post(this.httpConfig.BASE_ENDPOINT + 'store/getreservablestores', data, { observe: 'response' })
            .pipe(flatMap((res: HttpResponse<any>) => {
                if (res.ok) {
                    return of(res.body);
                }
                else {
                    return of(null);
                }
            }));
    }

    public getStoreGuidByMerchantName(storeName: string) {
        return this.httpClient.post(this.httpConfig.BASE_ENDPOINT + 'store/getstoreguidbyhostname', { "HostName": 'nplekkie.com/' + storeName }, { observe: 'response' })
            .pipe(flatMap((res: HttpResponse<any>) => {
                if (res.ok) {
                    return of(res.body);
                }
                else {
                    return of(null);
                }
            }));
    }

    public getPaymentMethods(storeGuid) {
        return this.httpClient.get<MolliePaymentMethods>(this.httpConfig.BASE_ENDPOINT + '/reservation/' + storeGuid + '/paymentmethods')
            .pipe(map((result: MolliePaymentMethods) => {
                return result;
            }));
    }

    public processGuestRegistrationForm(storeGuid: string, data: IProcessGuestRegistrationRequestDto){
        const url = `${this.httpConfig.BASE_ENDPOINT}reservation/${storeGuid}/processGuestRegistration`;
        return this.httpClient.post(url, data, { observe: 'response' })
            .pipe(flatMap((res: HttpResponse<any>) => {
                if (res.ok) {
                    return of(res.body);
                }
                else {
                    return of(null);
                }
            }));
    }

    public getSettings() {
        return from(this.storage.get('store').then((settings: IReservationSettings) => { return settings }));
    }

    public unSetStore() {
        this.settingsRetrieved$.next(null);
    }

    private storeSettings(settings: IReservationSettings): Observable<any> {
        return from(this.storage.set('store', settings).then((settings: IReservationSettings) => { return settings }));
    }
}