<div class="container">
    <div class="scroll" scrollX="true" scrollY="true">
        <ul>
            <li [class.selected]="item.value === getSelectedValue()" *ngFor="let item of items; let i=index"
                (click)="selectValue(item.value)">
                <div [class.circle]="!asTime" [class.oval]="asTime">
                    <span>{{item.text}}</span>
                </div>
            </li>
        </ul>
    </div>
</div>